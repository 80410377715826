<template>
  <footer>
    <b-container>
      <b-row>
        <b-col cols="12" md="4" class="mb-2 mb-md-0">
          Юридическая поддержка:<br>
          OOO«ЭКСПЕРТ ЛЕГАЛ СЕРВИС»,<br>
          УНП 193264443
        </b-col>
        <b-col cols="12" md="4" class="mb-2 mb-md-0">
          Линия поддержки:<br>
          <a class="color-black" href="tel:375173367650">7650, +375 17 336 7650</a><br>
          <a class="color-black" href="mailto:info@els24.by">info@els24.by</a>
        </b-col>
        <b-col cols="12" md="4">
          Вопросы РКО:<br>
          <a class="color-site text-underline" target="_blank" referrerpolicy="no-referrer"
             href="//www.vtb-bank.by/kontakty-banka">Горячая линия Банка ВТБ</a>
        </b-col>
      </b-row>
    </b-container>

    <u-modal id="info-modal">
      <template v-slot:header>
        <h5 class="text-center font-weight-bold mb-3">Внимание!</h5>
      </template>
      <template v-slot:default>
        <p class="text-center">Сайт временно недоступен по техническим причинам.</p>
      </template>
    </u-modal>

    <u-modal id="call-modal">
      <template v-slot:default>
        <basic-form :form="form" form-class="form-short" :submit-button="submitButton"/>
      </template>
    </u-modal>
    <u-modal id="success-modal">
      <template v-slot:header>
        <h5 class="text-center font-weight-bold mb-3">Сообщение отправлено.</h5>
      </template>
      <template v-slot:default>
        <p class="text-center">Ваше сообщение успешно отправлено!</p>
      </template>
    </u-modal>
    <u-modal id="failed-modal">
      <template v-slot:header>
        <h5 class="text-center font-weight-bold mb-3">Произошла ошибка!</h5>
      </template>
      <template v-slot:default>
        <p class="text-center">Возникла ошибка при отправке сообщения. Вернитесь обратно и попробуйте еще
          раз.</p>
      </template>
    </u-modal>
  </footer>
</template>

<script>
import {Form} from "../../classes/form";
import {InputGroup} from "../../classes/form/inputGroup";
import {Input, Checkbox} from "../../classes/form/input";
import {required, email, minLength, maxLength} from 'vuelidate/lib/validators'

export default {
  name: "index",
  components: {
    UModal: () => import(/* webpackChunkName: "u-modal" */'../UModal'),
    BasicForm: () => import(/* webpackChunkName: 'basic-form-in-header'*/'../BasicForm/'),
  },
  data() {
    return {
      submitButton: {
        variant: 'u-button',
        text: 'Отправить запрос',
        size: 'small long',
        classButton: 'd-block mx-auto',
        type: 'submit',
      },
    }
  },
  computed: {
    form() {
      return new Form(
          'Нужна помощь?',
          [
            (new InputGroup(
                    (new Input(
                        'tn',
                        '',
                        '7',
                        'd-none',
                        '',
                        '',
                        '',
                    )).setValidatorOptions({
                      required
                    }),
                    (new Input(
                        'type',
                        '',
                        'Нужна помощь?',
                        'd-none',
                        '',
                        '',
                        '',
                    )).setValidatorOptions({
                      required
                    }),
                    (new Input('help_request_name', 'ФИО', '', 'col-md-12',)
                    ).setValidatorOptions(
                        {
                          required,

                        }
                    ),
                    (new Input('help_request_phone', 'Телефон', '+375', 'col-md-12', '', '+375',)
                    ).setValidatorOptions(
                        {
                          required,
                          alpha: val => /^[+]*[(]?[0-9][)]?[-\s0-9]*$/.test(val),
                          minLength: minLength(13),
                          maxLength: maxLength(16)
                        }
                    ),
                    (new Input('help_request_email', 'E-mail', '', 'col-md-12', '')
                    ).setValidatorOptions(
                        {
                          required,
                          email
                        }
                    ),
                    (new Checkbox('help_request_agree',
                            'Оставляя заявку, вы принимаете условия <a target="_blank" referrerpolicy="no-referrer" class="color-black text-underline" href="/docs/soglasiye-na-personalnyye-dannyye.pdf">Пользовательского соглашения и Политики конфиденциальности</a>.',
                            'help_request_agree', 'col-md-12')
                    ).setValidatorOptions(
                        {required}
                    ),
                )
            ),
          ]
      )
    },
  },
}
</script>

<style scoped lang="scss">
footer {
  padding: 30px 0;
  border-top: 1px solid $gray;
  border-bottom: 20px solid $site-color-light;
  @include _lg {
    padding: 10px 0;
  }
}
</style>